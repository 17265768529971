.edit-section {
  display: flex;
  width: 100%;
}

.edit-main {
  position: relative;
  width: 85%;
}

.edit-label {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #01384d;
}

.edit-box {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  width: 100%;
}

.edit-form {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.edit-input {
  border: 1.5px solid #575dfb;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 48px;
  letter-spacing: -0.011em;
  margin: 5px 0;
  padding: 5px 15px;
  outline: #575dfb;
  color: #50516a;
}

.edit-box:nth-child(1) {
  margin-top: 0;
}

.edit-right {
  width: 100%;
}

.edit__left {
  margin-right: 30px;
}

.eidt-left {
  width: 100%;
}

.edit-avatar {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 1px 1px 11px -7px rgba(0, 0, 0, 0.6);
  border: 1px solid #fff;
}

.edit-btn {
  width: 105px;
  height: 40px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  border: none;
  color: white;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-textarea {
  width: 100%;
  height: 117px;
  resize: none;
  outline: #575dfb;
}

.edit-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-plus {
  background: #01384d;
  border: 2px solid #ffffff;
  border-radius: 2px;
  color: white;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.edit-footer .edit-add {
  border-radius: 16px 16px 0px 0px;
  width: 100%;
  height: 56px;
  background-image: url("../../Assets/Img/acc.png");
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100% 95%;
}
.edit-footer .MuiAppBar-root {
  align-items: flex-start;
}

.edit-add-box {
  display: flex;
  align-items: center;
  padding: 0 15px !important;
}

.edit-footer .MuiPaper-elevation1 {
  border-radius: 0px 0px 16px 16px;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 1%),
        0px 1px 1px 0px rgb(0 0 0 / 1%),
        0px 1px 3px 0px rgb(0 0 0 / 10%) */
  box-shadow: none;
}

.edit-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #01384d;
  margin-left: 10px;
}

.edit-footer .MuiAccordion-rounded:first-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.edit-footer .MuiAccordionSummary-expandIcon.Mui-expanded,
.edit-footer .MuiAccordionSummary-expandIcon {
  display: none;
}

.add-btn {
  width: 56px;
  height: 19px;

  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: -0.011em;

  background-color: transparent;
  border: none;
  color: #071014;
  transition: 0.3s;
  cursor: pointer;
}

.add-btn:hover {
  color: #673ab7;
}

.edit-footer {
  margin-top: 25px;
}

.edit-list {
  padding: 15px;
}

.add-box {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.add-box:nth-child(1) {
  padding-top: 0;
}

.add-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  margin-bottom: 5px;
  color: #01384d;
}

.add-input {
  border: 1.5px solid #e5ecfc;
  border-radius: 8px;
  width: 587px;
  height: 40px;
  padding: 0px 10px;
  outline: #512da8;
}

.add-cancel {
  width: 105px;
  height: 40px;

  background: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #01384d;
  border: 1px solid #809eb1;
  border-radius: 8px;

  transition: 0.3s;
}

.add-create {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  width: 105px;
  height: 40px;
  transition: 0.3s;

  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  border: 2px solid white;
}

.add-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.social-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 13px;
  width: 100%;
  height: 57px;
  padding-right: 2px;
  background: #84b0ec;
  border-radius: 12px;
}

.social-item:nth-child(2) {
  background: #b3389f;
}

.social-btn {
  background-color: transparent;
  border: none;
  margin-right: 9px;
}

.social-icon {
  width: 32px;
  height: 32px;

  background: #ffffff;
  border-radius: 10px;
}

.social-name {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-align: start;
  width: 520px;

  color: #ffffff;
  padding: 0px 18px;
  height: 24px;
}

.social-item span {
  padding-right: 10px;
  /* border-right: 1px solid #E5ECFC; */
}

.edit-footer .css-lx4kgr {
  /* background-color: rgb(255, 5, 5); */
  width: 105.3%;
  border: none;
  box-shadow: none;
}

.edit-footer .css-xqhexu-MuiPaper-root-MuiAppBar-root {
  border: none;
  box-shadow: none;
  background-color: transparent;
  /* background-color: yellow; */
}

/* .css-1lwbda4-MuiStack-root  */

.edit-footer .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000000;
}

.edit-footer .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.edit-footer .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.edit-footer
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  /* width: 260px;
 height: 56px;
    
border: 1.5px solid #575DFB;
border-radius: 16px; */
  border-radius: 16px;
}

.edit-footer
  .edit-footer
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-radius: 16px;
}

.edit-footer .schedule-box .edit-date {
  border-radius: 16px;
}

.edit-footer .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  width: 260px;
  height: 56px;

  border: none;
  border-radius: 16px;
}

.edit-footer .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 292px;
  height: 56px;
  border: 1.5px solid #575dfb;
  border-radius: 16px;
  border: none;
}

.edit-footer
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #575dfb;
}

.schedule-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.schedule-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #8898a1;
}

.edit-footer .css-1ujykiq-MuiButtonBase-root-MuiTab-root {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
  color: #01384d;
}

.schedule-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 595px;
  margin: 37px 0;
}

.edit-footer .schedule-footers {
  margin: 0;
  padding: 0;
}

.animation-select {
  border: 1.5px solid #e5ecfc;
  border-radius: 8px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 10px 15px;
  margin: 25px 0;
  color: #8898a1;
  margin-top: 2px;
}
.edit-footer .MuiTypography-body1 {
  width: 100%;
}
.animation-select option {
  border: 1.5px solid #e5ecfc;
  border-radius: 8px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #8898a1;
  padding-left: 15px;
}

.animation-btn {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.011em;
  border: none;
  color: #ffffff;
  width: 343px;
  height: 56px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 16px;
}

.animation-btn {
  margin: 0 auto;
}

.animation-footer {
  /* border-top: 1px solid #E5ECFC; */
  padding: 10px;
  margin-top: 15px;
}

.redirect-title {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;

  /* default */

  color: #8898a1;
  /* margin-top: px; */
}

/* *************************************** */

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  width: 500px;
  height: 250px;
}

body .css-19kzrtu {
  /* height: 270px; */
  height: 100%;
}

.MuiTypography-root .MuiBox-root div .react-swipeable-view-container {
  transform: translate(300%, 0%);
}

.controls {
  position: absolute;
  bottom: 28px;
  left: 29%;
  width: 50%;
  /* transform: translateX(-50%); */
  height: 40px;

  display: flex;
  align-items: center;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 2px solid #673ab7;
  border-bottom: 2px solid #673ab7;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.thumbnail {
  width: 350px;
  height: 390px;
  position: absolute;
  background-color: white;
  border-radius: 12px 12px;
}

.thumbnail .reactEasyCrop_Container {
  border: 0 solid rgba(0, 0, 0, 0.6);
  border-radius: 12px 12px 0px 0px;

  padding: 0;
  width: 100%;
  margin: 0;
  height: 250px;
  background-color: #7f7f7f;
  /* border: 100px solid rgba(0, 0, 0, 0.6); */
}

.controls .zoom-range {
  background-color: #3f51b5;
}

.thumbnail-form {
  margin-left: 25px;
}

.thumbnail-form label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 120px;
  height: 120px;
  /* background-color: red; */
  margin: 12px auto;
}

.thumbnail-footer {
  margin: 25px 0;
  padding: 15px 0;
  /* border-top: 1px solid #01384D; */
}
.thumbnail-form {
  position: relative;
}
.thumbnail-form img {
  position: absolute;
  top: 0%;
  left: 38%;
  transform: translate(0, 0);
}
body .MuiButton-containedPrimary {
  overflow: hidden;
  display: none;
}

.thumbnail {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}
body .thumbnail .container-cropper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.thumbnail .container-cropper {
  width: 350px;
  height: 313px;
}
.reactEasyCrop_Contain {
  width: 100%;
}
.thumbnail .slider {
  width: 250px;
  height: 20px;
  margin-left: 56px;
}
.thumbnail .reactEasyCrop_Contain {
  height: 100%;
}

.thumbnail-modal-footer {
  padding: 20px;
  margin: 0;
  margin-top: 20px;
}

/* .edit-footer .MuiTypography-body1:nth-child(1) {
    width: 632px;
}
.edit-footer .css-1ujnqem-MuiTabs-root{
    width: 100%;
}
.edit-footer .MuiAccordionDetails-root{
    padding: 0;
} */
/* .edit-footer .edit-add-box{
    width: 100% !important;
} */

body .css-xqhexu-MuiPaper-root-MuiAppBar-root {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
}
body .modal_content .css-xqhexu-MuiPaper-root-MuiAppBar-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jello {
  animation: jello1 2s infinite;
  transform-origin: center;
}
@keyframes jello1 {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}

.bounce {
  animation: bounce 2s infinite;
  transform-origin: center;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-100%);
  }
  5% {
    -webkit-transform: translateY(-100%);
  }
  15% {
    -webkit-transform: translateY(0);
    padding: 4px auto;
  }
  20% {
    -webkit-transform: translateY(-80%);
  }
  25% {
    -webkit-transform: translateY(0%);
    padding: 4px auto;
  }
  30% {
    -webkit-transform: translateY(-70%);
  }
  35% {
    -webkit-transform: translateY(0%);
    padding: 5px auto;
  }
  40% {
    -webkit-transform: translateY(-60%);
  }
  45% {
    -webkit-transform: translateY(0%);
    padding: 5px auto;
  }
  50% {
    -webkit-transform: translateY(-50%);
  }
  55% {
    -webkit-transform: translateY(0%);
    padding: 6px auto;
  }
  60% {
    -webkit-transform: translateY(-30%);
  }
  65% {
    -webkit-transform: translateY(0%);
    padding: 6px auto;
  }
  70% {
    -webkit-transform: translateY(-15%);
  }
  75% {
    -webkit-transform: translateY(0);
    padding: 8px auto;
  }
  80% {
    -webkit-transform: translateY(-10%);
  }
  85% {
    -webkit-transform: translateY(0);
    padding: 8px auto;
  }
  90% {
    -webkit-transform: translateY(-5%);
  }
  95% {
    -webkit-transform: translateY(0);
    padding: 10px;
  }
  100% {
    -webkit-transform: translateY(0);
    padding: 10px;
  }
}

/* Mozilla Firefox 15 below */
@-moz-keyframes bounce {
  0% {
    -moz-transform: translateY(-100%);
    opacity: 0;
  }
  5% {
    -moz-transform: translateY(-100%);
    opacity: 0;
  }
  15% {
    -moz-transform: translateY(0);
    padding-bottom: 5px;
  }
  30% {
    -moz-transform: translateY(-50%);
  }
  40% {
    -moz-transform: translateY(0%);
    padding-bottom: 6px;
  }
  50% {
    -moz-transform: translateY(-30%);
  }
  70% {
    -moz-transform: translateY(0%);
    padding-bottom: 7px;
  }
  80% {
    -moz-transform: translateY(-15%);
  }
  90% {
    -moz-transform: translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    -moz-transform: translateY(-10%);
  }
  97% {
    -moz-transform: translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    -moz-transform: translateY(-5%);
  }
  100% {
    -moz-transform: translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

/* Opera 12.0 */
@-o-keyframes bounce {
  0% {
    -o-transform: translateY(-100%);
    opacity: 0;
  }
  5% {
    -o-transform: translateY(-100%);
    opacity: 0;
  }
  15% {
    -o-transform: translateY(0);
    padding-bottom: 5px;
  }
  30% {
    -o-transform: translateY(-50%);
  }
  40% {
    -o-transform: translateY(0%);
    padding-bottom: 6px;
  }
  50% {
    -o-transform: translateY(-30%);
  }
  70% {
    -o-transform: translateY(0%);
    padding-bottom: 7px;
  }
  80% {
    -o-transform: translateY(-15%);
  }
  90% {
    -o-transform: translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    -o-transform: translateY(-10%);
  }
  97% {
    -o-transform: translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    -o-transform: translateY(-5%);
  }
  100% {
    -o-transform: translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

/* W3, Opera 12+, Firefox 16+ */
@keyframes bounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  5% {
    transform: translateY(-100%);
    opacity: 0;
  }
  15% {
    transform: translateY(0);
    padding-bottom: 5px;
  }
  30% {
    transform: translateY(-50%);
  }
  40% {
    transform: translateY(0%);
    padding-bottom: 6px;
  }
  50% {
    transform: translateY(-30%);
  }
  70% {
    transform: translateY(0%);
    padding-bottom: 7px;
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.shake {
  animation: shake1 2s infinite;
}

@keyframes shake1 {
  0% {
    transform: translateX(0);
  }
  2.66667%,
  8%,
  13.3333%,
  18.6667% {
    transform: translateX(-10px);
  }
  5.33333%,
  10.6667%,
  16% {
    transform: translateX(10px);
  }
  21.3333% {
    transform: translateX(8px);
  }
  24% {
    transform: translateX(-8px);
  }
  /* 50% { transform: rotate(0deg); }
    75% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); } */
}

.vibrate {
  animation: vibrate1 2s infinite;
}

@keyframes vibrate1 {
  0% {
    transform: translate(0);
  }
  2% {
    transform: translate(-2px, 2px);
  }
  4% {
    transform: translate(-2px, -2px);
  }
  6% {
    transform: translate(2px, 2px);
  }
  8% {
    transform: translate(2px, -2px);
  }
  10% {
    transform: translate(0);
  }
}

.animated {
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}
.animated.hinge {
  animation-duration: 2s;
}

@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1.4deg);
  }
  30% {
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 2.4deg);
  }
  45% {
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2.4deg);
  }
  60% {
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 2.4deg);
  }
  75% {
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1.4deg);
  }
  100% {
    transform: none;
  }
}

.wobble {
  animation: wobble 1s ease infinite;
}

body .modal_content .css-xqhexu-MuiPaper-root-MuiAppBar-root {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiPaper-root .MuiAccordionSummary-root {
  padding: 0 !important;
}

li {
  list-style: none;
}
.MuiAccordionSummary-content.Mui-expanded:nth-child(1) {
  margin: 20px 0 !important;
}
.MuiPaper-root .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiPaper-root:nth-child(1) .MuiAccordionSummary-content.Mui-expanded {
  margin: 20px 0 !important;
}

.Mui-expanded .social-item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.MuiAccordionDetails-root {
  padding: 8px 16px 16px !important;
}
.MuiAccordionDetails-root:nth-child(1) {
  background-color: white;
  padding-top: 0 !important;
  /* padding-left: 0 !important; */
}

.MuiPaper-root .MuiAccordionSummary-content {
  margin-bottom: 0px !important;
  width: 100%;
}
.MuiPaper-root:nth-child(1) .MuiAccordionSummary-content {
  margin-bottom: 12px !important;
}

.pro-box p {
  color: #7373aa;
  font-size: 0.875rem;
  line-height: 1.6;
  width: 344px;
}

.pro-btn {
  background: #6bc818;
  font-size: 14px;
  line-height: 0.8em;
  min-height: 42px;
  padding: 0 28px;
  border: 0;
  border-radius: 6.25rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
}
.pro-link{
  background: #6bc818;
  font-size: 16px;
  line-height: 0.8em;
   padding: 15px 25px;
  border: 0;
  border-radius: 6.25rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-weight: 500;

}
.pro-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

@media (max-width: 920px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
}
@media (max-width: 820px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
  .MuiAccordionDetails-root:nth-child(1) {
    padding-left: 0 !important;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
}

@media (max-width: 620px) {
  .container {
    width: 400px;
  }
  .edit-main .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
}

@media (max-width: 520px) {
  .container {
    width: 400px;
  }
  .edit-main .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .container {
    width: 300px;
  }
  .edit__left {
    margin-right: 0;
  }

  .edit-btn {
    width: 100%;
    margin-top: 18px;
    font-size: 16px;
  }

  .edit-main .edit-footer .MuiTypography-body1 {
    width: 100%;
  }
  .edit-footer .pro-box p {
    width: 100% !important;
    text-align: center;
    font-size: 15px !important;
    line-height: 19px;
    font-weight: 300 !important;
    margin-bottom: 12px;
  }
  .add-form .pro-box{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
   
  .pro-btn {
    background: #6bc818;
    font-size: 12px;
    line-height: 15px;
    min-height: 31px;
    padding: 0px;
    width: 30%;
    border: 0;
    border-radius: 6.25rem;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-weight: 300;
  }
  .pro-box {
    margin-top: 0;
  }
  .social-name {
    width: 100% !important;
  }
  .makeStyles-modal-2 .crop-container {
    width: 100% !important;
    height: 100% !important;
  }
  body .makeStyles-modal-2 {
    min-width: 86%;
    height: 60%;
  }
  .makeStyles-modal-2 .buttons {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 47px;
    background-size: 100% 92%;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 39px !important;
  }
  .days-schedule{
    display: flex;
    flex-direction: column;

  }
  
}

.social-icon {
  width: 38px !important;
  height: 38px !important;
  background: transparent;
  color: white;
  fill: white;
}
.social-svg {
  width: 76% !important;
  height: 76% !important;
  top: 4px !important;
  left: 4px !important;
  fill: white;
  background: white;
}

.MuiTouchRipple-root {
  background-color: transparent !important;
}

.MuiBox-root .MuiPaper-elevation {
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #01384d;
}

.edit-footer .MuiPaper-root .MuiTabs-root {
  margin: 0;
  width: 100%;
}

.edit-add {
  height: 35px;
}

@media (max-width: 1460px) {
  .container {
    width: 1280px;
  }
  .social-link {
    width: 100%;
  }
  .social-name {
    width: 637px;
  }
  .edit-add-box {
    padding-left: 0;
  }
}
@media (max-width: 1300px) {
  .container {
    width: 1010px;
  }
  .social-link {
    width: 100%;
  }
}
.day-premium{
  display: flex;
   
  width: 100%;
  margin: 20px 0;
  padding:0 5px;
  flex-direction: column;
  
}
.days-schedule{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.day-start{
  display: flex;
  flex-direction: column;
}
.day-start label{
  font-size: 15px;
  padding-bottom: 5px;
}
.day-start input{
  padding: 10px;
  width: 290px;
  border: 1.5px solid #575DFB;
  border-radius: 10px;
  font-size: 16px;
}
.add-form{
  padding: 6px 0;
}


.schedule-toggle[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

 .schedule-label {
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.schedule-label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 90px;
	transition: 0.2s;
}

.schedule-toggle:checked + .schedule-label {
	background: #bada55;
}

.schedule-toggle:checked + .schedule-label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

 
.add-form-preview{
  width: 253px;
  height: 46px;
  background: linear-gradient(180deg, #673AB7 0%, #512DA8 100%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.111em;
  color: #FFFFFF;
  font-family: 'Clarika Geometric';
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


.link-image-uploader .image-upload-hover {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9d9d9d52;
   
  position: absolute;
  top: 0;
  opacity: 0;
  border: 2px dashed #fff;
}
.link-image-uploader .image-upload-hover:hover {
  border-radius: 100%;
}

.link-avatar{
    width: 140px;
    height: 140px;
    object-fit: cover;
    box-shadow: 1px 1px 11px -7px rgb(0 0 0 / 60%);
    border: 1px solid #fff;

}