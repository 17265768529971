.admin-footer{
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    height: 40px;
    
}
.admin-footer .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
 
}
.admin-footer-text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #000000;

}