.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  
    background-color: rgba(0,0,0,0.5);
    /* backdrop-filter: blur(18px); */
  
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .exit-modal {
    background: white;
    padding: 2rem;
    transition: all 0.4s ease;
    border-radius: 16px;
 height: 235px;
  }
  
  .modal_content {
    width: 100%;
    height: 100%;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
  }
  .modalContainer.show {
    visibility: visible;
    transition: all 0.2s ease !important;
    
   
  }
  
  .modalContainer.show .modal {
    opacity: 1;
    animation-name: fadeon;
    animation-iteration-count: 1;
    animation-duration: 0.5s;
  }
  @keyframes fadeon {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
  }
  @keyframes fadeoff {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
  }

 
