@media (max-width: 1450px) {
  .container {
    width: 1280px;
  }
  .img-box7 {
    left: 77%;
  }
}

@media (max-width: 1290px) {
  .container {
    width: 1280px;
    padding: 0 15px;
  }

  .statistic-img {
    width: 626px;
  }

  .img-box7 {
    left: 75%;
  }
}

@media (max-width: 1180px) {
  .container {
    width: 1000px;
  }

  .header-img {
    width: 504px;
    object-fit: cover;
    height: 505px;
  }

  .profile-title {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 54px;
    /* text-align: center; */
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 436px;

    height: 52px;
    color: #182f43;
  }

  .profile-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;

    width: 438px;
    height: 96px;
    color: #182f43;
  }

  .people-img {
    width: 454px;
  }

  .statistic-img {
    width: 546px;
  }

  .img-box7 {
    left: 70%;
  }
}

@media (max-width: 1020px) {
  .container {
    width: 900px;
  }

  .header-img {
    width: 455px;
    object-fit: contain;
  }

  .profile-title {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 38px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 250px;
    /* background: red; */
    height: 82px;
    color: #182f43;
  }

  .people-title {
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 38px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 340px;

    height: 76px;
    color: #182f43;
  }

  .profile-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;

    width: 338px;
    height: 121px;
    color: #182f43;
  }

  .people-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;

    width: 430px;
    height: 96px;
    color: #182f43;
  }

  .people-img,
  .statistic-img {
    width: 400px;
  }

  .statistic-img {
    width: 546px;
  }

  .img-box7 {
    left: 70%;
  }

  /* .img-box */

  .micro-img {
    width: 60px;
    height: 60px;
  }

  .img-box2 img {
    width: 100px;
    height: 100px;
  }

  .img-box3 img {
    width: 90px;
    height: 100px;
  }

  .img-box3 {
    left: 50%;
    top: 36%;
  }

  .statistic-img {
    width: 505px;
  }
}

@media (max-width: 920px) {
  .container {
    width: 100%;
  }

  .header-title {
    font-style: normal;
    font-weight: 700;
    font-size: 49px;
    line-height: 58px;
    letter-spacing: -0.011em;
    color: #000000;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    /* background-color: yellow; */
    width: 363px;
    height: 119px;
  }

  .header-text {
    font-family: "Clarika Geometric";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    /* background-color: yellow; */
    width: 352px;
    height: 128px;
    letter-spacing: -0.011em;
    color: #000000;
    margin: 28px 0;
    margin-top: 10px;
  }

  .header-img {
    width: 399px;
    /* object-fit: contain; */
    height: 561px;
  }

  .header {
    padding: 50px 0;
    padding-bottom: 45px;
  }

  .header-img {
    width: 399px;
    /* object-fit: cover; */
    height: 452px;
  }

  .people-img,
  .statistic-img,
  .profile-img {
    width: 332px;
  }

  .statistic-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 302px;
    height: 76px;

    padding: 0;
    color: #182f43;
  }

  .statistic-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 321px;

    height: 70.5px;
    color: #182f43;
  }

  .footer-text {
    font-family: "Clarika Geometric";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.011em;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 706px;
    height: 45px;
    text-align: center;
    /* background-color: red; */
    color: #ffffff;
  }

  .footer {
    /* background-image: url(http://localhost:3000/static/media/f2.cac741d….png); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 25px;
    padding-bottom: 30px;
  }

  .footer-title {
    margin-top: 100px;
  }

  .statistic-img {
    width: 423px;
  }
}

@media (max-width: 820px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }

  .nav-item {
    margin: 0;
  }

  .header {
    padding-top: 150px;
    padding-bottom: 0;
  }

  .nav-item:nth-child(4) .nav-link,
  .nav-item:nth-child(3) .nav-link {
    padding: 10px 10px;
    margin-left: 10px;
  }

  .header-img {
    width: 357px;
    /* object-fit: cover; */
    /* height: 650px; */
  }

  .profile-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 300px;

    height: 93px;
    color: #182f43;
  }

  .statistic-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 298px;
    height: 64.5px;

    color: #182f43;
  }

  .people-text {
    width: 374px;
    height: 96px;
    color: #182f43;
  }

  .img-text {
    font-size: 19px;
    line-height: 19px;
  }

  .statistic-img {
    width: 393px;
  }

  .micro-img {
    width: 42px;
    height: 43px;
  }
}

@media (max-width: 720px) {
  .container {
    width: 100%;
  }

  .nav-list {
    display: none;
  }

  .menu-btn {
    display: block;
  }

  .header .container {
    flex-direction: column-reverse;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-right {
    display: none;
  }

  .header-left {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .useful-text {
    width: 475px;
    height: 61px;
  }

  .useful-form {
    margin-top: 0;
  }

  .people .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .statistic-left,
  .statistic-right,
  .people-left,
  .people-right,
  .profile-left,
  .profile-right {
    width: 60%;
    text-align: center;
  }

  .people-title {
    text-align: center;
    margin-top: 20px;
  }

  .profile .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profile-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profile-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    text-align: start;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 373px;
    margin-top: 25px;
    height: 40px;
    color: #182f43;
  }

  .profile-text {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 364px;
    height: 74px;
    margin-top: 8px;
    color: #182f43;
  }

  .statistic .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .statistic-title {
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 353px;
    height: 70px;
    padding: 0;
    margin-top: 15px;
    color: #182f43;
  }

  .statistic-text {
    margin-top: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 366px;
    height: 64.5px;
    color: #182f43;
  }

  .footer-title {
    font-family: "Clarika Geometric";
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.011em;
    color: #ffffff;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 423px;
    height: 34px;
    /* background-color: red; */
    margin-top: 70px;
    margin-bottom: 15px;
  }

  .footer-text {
    font-family: "Clarika Geometric";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.011em;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 566px;
    height: 38px;
    text-align: center;
    /* background-color: red; */
    color: #ffffff;
  }

  .footer {
    padding-top: 54px;
  }

  .footer-links {
    margin: 0px 13px;
  }
}

@media (max-width: 620px) {
  .container {
    width: 500px;
  }

  .footer-text {
    width: 486px;
  }

  .header {
    padding-bottom: 40px;
  }

  .img-box2 {
    top: 0%;
  }

  .img-box3 {
    top: 31%;
    left: 60%;
  }

  .footer-links {
    font-size: 20px;
    line-height: 32px;
    margin: 0 8px;
  }
}

@media (max-width: 520px) {
  .container {
    width: 400px;
  }

  .useful-text {
    width: 375px;
    height: 61px;
  }

  .header {
    padding-bottom: 20px;
  }

  .useful-title {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    width: 330px;
    color: #000000;
  }

  .img-box3 {
    top: 37%;
    left: 62%;
  }

  .profile-img,
  .statistic-img,
  .people-img {
    width: 260px;
    height: 260px;
  }

  .profile-title,
  .statistic-title,
  .people-title {
    width: 270px;
    height: 62px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #182f43;
  }

  .statistic-title {
    height: 71px;
  }

  .profile-text,
  .statistic-text,
  .people-text {
    width: 290px;
    height: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Dark/Main */

    color: #182f43;
  }

  .people-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .footer-title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;

    text-align: center;
    letter-spacing: -0.011em;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 386px;

    height: 79px;
    color: #ffffff;
  }

  .footer-link {
    margin: 5px 0;
    margin-bottom: 10px;
  }

  .footer-text {
    display: none;
  }

  .footer-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .footer-item {
    margin: 6px 0;
  }

  .statistic-img {
    width: 293px;
  }

  .micro-img {
    width: 32px;
    height: 33px;
  }

  .img-text {
    font-size: 12px;
    line-height: 13px;
  }

  .img-box2 img,
  .img-box3 img {
    width: 32px;
    height: 32px;
  }

  .img-box3 img {
    width: 42px;
    height: 42px;
  }

  .img-box2 {
    top: 8%;
  }
}

@media (max-width: 420px) {
  .container {
    width: 100%;
    padding: 0px 15px;
  }

  .header-title {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.011em;
    color: #000000;
  }

  .header-subtext {
    margin: 0;
    margin-top: 15px;
  }

  .header-text {
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 13px 0;
    margin-top: 2px;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.011em;
    color: rgba(0, 0, 0, 0.8);
  }

  .header-left {
    padding: 0;
    margin: 0;
  }

  .header {
    padding: 50px 0;
    /* padding-bottom: 0; */
  }

  .header-link {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
    color: #ffffff;
    padding: 6px 21px;
  }

  .useful-text {
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 10px 0;
    margin-top: 2px;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.011em;
    color: rgba(0, 0, 0, 0.8);
  }

  .useful-title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.011em;
    width: 100%;
    height: 37px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .useful-input {
    width: 100%;
  }

  .useful {
    margin: 38px 0;
    padding: 0;
  }

  .useful-btn {
    position: absolute;
    top: 55%;
    left: 10%;
  }

  .profile-img,
  .statistic-img,
  .people-img {
    width: 100%;
    height: 100%;
  }

  .profile-title,
  .statistic-title,
  .people-title {
    width: 100%;
    height: 100%;
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;

    margin: 15px auto;

    font-size: 24px;
    line-height: 30px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #182f43;
  }

  .statistic-title {
    height: 71px;
  }

  .profile .container,
  .people .container,
  .statistic .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin: 28px 0;
  }

  .profile .container {
    flex-direction: column;
  }

  .profile-left,
  .profile-right,
  .people-left,
  .people-right,
  .statistic-left,
  .statistic-right {
    width: 100%;
  }

  .profile-text,
  .statistic-text,
  .people-text {
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
    font-weight: 400;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    color: #182f43;
  }

  .footer {
    background-image: none;
    background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    padding-top: 0;
  }

  .footer-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.011em;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 301px;
    /* background: red; */
    height: 69px;
  }

  .header-title {
    font-style: normal;
    font-weight: 600;
    width: 100%;

    font-size: 30px;
    height: 95px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.011em;
    color: #000000;
  }

  .img-box3 {
    top: 39%;
    left: 51.1%;
  }

  .img-box3 img {
    width: 53px;
    height: 53px;
  }

  .forget-page .register .register-btn {
    padding: 8px 75px;
  }
}
