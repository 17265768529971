.refer-top {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  width: 100%;
  height: 52px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 16px;
  color: #ffffff;
}

.refer-medium {
  width: 100%;
  height: 160px;
  margin: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  padding: 10px 25px;
  position: relative;
  overflow: hidden;
}

.refer-title {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #01384d;
}

.refer-input {
  width: 100%;
  height: 50px;

  padding: 0 20px;
  background: #fdfdff;
  outline: #512da8;
  border: 1px solid #e5ecfc;
  border-radius: 10px;
}

.refer-form {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.img-wrapper {
  max-width: 30px;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  height: 30px;
}
.img-wrapper img {
  width: 100%;
}
.refer-btn {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  min-width: 127px;
  color: #ffffff;
  border: none;
  padding: 8px 23px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 10px;
}

.refer-bottom {
  width: 100%;
  min-height: 220px;

  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 16px;
}
.refer-list {
  max-height: 300px;
  overflow: auto;
}
.refer-box {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #01384d;
  padding: 8px 15px;
  width: 300px;
}

.refer-left {
  width: 400px;
  display: flex;
  align-items: center;
}

.refer-name {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01384d;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.refer-time {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01384d;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.refer-state {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #01384d;
  padding: 6px 15px;
  background: #e5ecfc;
  border-radius: 20px;
}

.refer-right,
.refer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refer-right {
  width: 290px;
}

.refer-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
  padding: 8px 25px;
  width: 100%;
}

.refer-item {
  padding: 12px 25px;
  margin: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.refer-item:last-child {
  border-bottom: 0 solid rgba(0, 0, 0, 0.45);
}

.refer-items {
  margin: 0;
  background: #e5ecfc;
}

.refer-box1,
.refer-time {
  text-align: left;
  width: 110px;
}

/* .refer-box2,
.refer-state {
  width: 105px;
} */
.disabled-overlay {
  background-color: #00000087;
  color: rgb(226, 226, 226);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.refer-name {
  margin-left: 10px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01384d;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
}
