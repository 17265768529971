.register-page,
.login-page,
.forget-page,
.code-forget-page,
.signup-page {
  background-image: url("../../Assets/Img/left1.png"),
    url("../../Assets/Img/right1.png");
  background-repeat: no-repeat;
  background-size: 17%, 15%;
  background-position: top 100% left 0, top 0 left 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.login-page {
  min-height: 100vh;
}
.register-b {
  display: none;
}

.register-error {
  display: none;
}

@media (max-width: 2240px) {
  .register {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -60px;
  }
  .forget-page .register {
    margin-top: 0;
  }
}

@media (max-width: 1450px) {
  .container {
    width: 1280px;
  }
}

@media (max-width: 1290px) {
  .container {
    width: 1280px;
    padding: 0 15px;
  }
}

@media (max-width: 1180px) {
  .container {
    width: 1000px;
  }
}

@media (max-width: 1020px) {
  .container {
    width: 900px;
  }
}
@media (max-width: 920px) {
  .container {
    width: 100%;
  }
  .register-page,
  .login-page,
  .forget-page,
  .code-forget-page,
  .signup-page,
  .signup-page {
    background-image: none;
  }
}

@media (max-width: 820px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .admin-header-item {
    margin-left: 10px;
  }
}

@media (max-width: 720px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 620px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 520px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 420px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .register-input,
  .register-btn,
  .register .react-tel-input .form-control,
  .register-text {
    width: 265px !important;
  }
  .signup-page .register .container,
  .forget-page .register .container,
  .code-forget-page .register .container,
  .register-page .register .container,
  .login-page .register .container {
    width: 280px;
  }
  .register-text {
    width: 260px;
    height: 39px;
    font-size: 13px;
    line-height: 18px;
  }
  .login-page .register-blok {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .login-page .register-blok .register-link {
    margin-left: 0;
  }
  .forget-page .register-title {
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 26px;
    letter-spacing: -0.011em;
    background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
    background-clip: text;
  }
  .forget-page .register-text {
    width: 284px;
    height: 21px;
  }
  .forget-page .register-btn {
    margin-top: 8px;
  }

  .compare-page .register .register-text {
    width: 218px;
    height: 39px;
  }
  .compare-page .register .register-box:nth-child(1) {
    margin-top: 5px;
  }
  .signup-page .container .register-text {
    width: 195px;
    height: 35px;
  }
  .signup-page .register-title {
    font-size: 28px;
  }
  .register-page .register-btn {
    padding: 8px 67px !important;
    margin-top: 0;
  }
  .login-page .register-btn {
    margin-top: 10px;
  }
}
