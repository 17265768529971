.settings-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.settings-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.settings-title {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  padding: 10px 20px;
  color: #01384d;
}

.settings {
  min-height: 650px;
  background: #ffffff;
  padding: 0px;
  border: 0.5px solid #bfcdd3;
  border-radius: 8px;
  width: 90%;
}

.special-color {
  width: 90%;
  min-height: 301px;
  background: #ffffff;
  padding: 0px;
  border: 0.5px solid #bfcdd3;
  border-radius: 8px;
  margin: 25px 0;
}

.settings-list {
  border-top: 1px solid #01384d;
  padding: 20px 10px;
}

.settings-item {
  width: 24.3%;
  margin: 9px 0px;
  /* background-color: violet; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.special-top {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #01384d;
  padding: 10px 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.color-list,
.special-font {
  padding: 10px 22px;
}

.color-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-item {
  display: flex;
  margin: 15px 0;
  flex-direction: column;
}

.color-label {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #01384d;
}

.color-item div {
  display: flex;
  align-items: center;

  border: 1.5px solid #575dfb;
  border-radius: 8px;

  margin-top: 5px;
  width: 135px;
  height: 42px;

  padding: 0;
}

.color-input {
  border-radius: 6px;
  border: none;
  width: 44px;
  height: 40px;
  margin-left: 5px;
}

.color-item p {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 5px;
  letter-spacing: -0.011em;
  margin-left: 10px;
  color: #01384d;
}

.special-font {
  display: flex;
  flex-direction: column;
}

.special-select {
  border: 1.5px solid #575dfb;
  border-radius: 16px;
  width: 100%;
  height: 50px;
  padding: 5px 15px;
  margin-top: 5px;
  padding-right: 30px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;

  /* Gray */

  color: #abbac3;
  outline: #01384d;
}

.special-option {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 10px 0;
  letter-spacing: -0.011em;
  border-bottom: 1px solid #575dfb;
  color: #01384d;
}

.special-box {
  width: 330px;
  height: 420px;
  margin: 18px 0;
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 16px;
}

.special-fon {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #01384d;
  padding: 10px 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.special-blok {
  width: 100%;
}

.special-find {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #e5ecfc;
  background: #000000;
  border-radius: 4px;
  padding: 20px;
  margin: 18px auto;
  width: 262px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 206px;
}

.special-yuklash {
  margin-top: 15px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;

  color: #e5ecfc;
}

.special-range {
  display: flex;

  align-items: center;
  padding: 12px;
  margin: 0px 15px;
}

.input-range {
  width: 103px;
  height: 4px;
}

.special-blurs {
  margin-left: 55px;
}

.special-blok {
  display: flex;
}

.special-box1 {
  margin-left: 20px;
}

.button-list {
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  margin-top: 11px;
  margin-left: 5px;
}

.button-item {
  margin: 10px 22px;
  width: 30%;
}

.button-item button {
  width: 126px;
  height: 53px;
  color: white;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 20px;
  border: none;
}

.button-item:nth-child(2) button {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #01384d;
  background: #ffffff;
  border: 1px solid #01384d;
  border-radius: 20px;
}

.button-item:nth-child(3) button {
  background: #C8B9E4;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.button-item:nth-child(4) button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: #ffffff;
  border: 1px solid #01384d;
  border-radius: 8px;
  color: #01384d;
}

.button-item:nth-child(5) button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;

  color: #ffffff;
}

.button-item:nth-child(6) button {
  background: #ffffff;
  /* Gray 1 */

  border: 1px solid #01384d;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gray 1 */

  color: #01384d;
}

.color-inputs {
  width: 100px;
}

#lTop {
  width: 100px;
}

 

.custom-style {
  width: 90%;
  height: 443px;
  background: #ffffff;
  border: 0.5px solid #01384d;
  border-radius: 8px;
}

.custom-top {
  border-bottom: 0.5px solid #01384d;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #01384d;
  padding: 10px 24px;
}

.CodeMirror-scroll {
  background: #000000;
  color: white;
  height: 203px;
  width: 679px;
}

.CodeMirror-gutter {
  background: #01384d;
}

.custom-button {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  width: 105px;
  height: 40px;
  border: none;
  margin: 25px 0;
  color: #ffffff;
  margin-right: 19px;
}
.custom-style form{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.custom-bottom{
  width: 100%;
}


.special-upload {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 5px;
}

.special-search {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  background-color: transparent;
  border: none;
  margin: 15px 0;
  color: #e5ecfc;
}

.special-upload {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  color: #e5ecfc;
}

.search-icon {
  width: 15px;
  height: 15px;
}

.search-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.search-item {
  width: 24%;
}
.search-img {
  width: 180px;
  height: 150px;
  border-radius: 0.375rem;
}
.search-modal {
  width: 900px;
  border-radius: 15px;
  min-height: 570px;
  padding-bottom: 20px;
}
.search-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2px;
}
.search-input {
  width: 100%;
  border: 0;

  outline: none;
  padding: 5px 10px;
  padding-left: 50px;
  font-size: 16px;
  height: 28px;
  line-height: 1;
  color: #abbac3;
  padding-bottom: 0;
}
.search-form {
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #abbac3;
}
.search-icon {
  position: absolute;

  top: 25%;
  left: 2%;
}
.search-close {
  background-color: transparent;
  border: none;
  color: #abbac3;
  font-weight: bolder;
  font-size: 25px;
  margin-right: 20px;
}
.search-title {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.625rem;
  bottom: 0.3125rem;
  color: #fff;
  font-size: 0.75rem;
  left: 50%;
  max-width: 90%;
  overflow: hidden;
  padding: 0.125rem 0.375rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-item {
  margin: 10px 1px;
  position: relative;
}
.search-title {
  position: absolute;
  top: 75%;
  left: 25%;
  height: 23px;
  padding: 3px 5px;
  opacity: 0.7;
}

.color-form, .special-forms{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.color-btn{
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  width: 105px;
  height: 40px;
  border: none;
  margin: 20px 0;
  margin-right: 20px;
  color: white;
  transition: 0.3s;
  border: 2px solid white;
  cursor: pointer;
}
.color-btn:hover{
  background: white;
  color: #512da8;
  transition: 0.3s;
  cursor: pointer;
border: 2px solid #512da8;
}
.img-btn{
  margin-top: 10px;
}
 
.img-form{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}
.special-buttons{
  height: 89%;
}

.css-wxvlna{
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
}
.css-wxvlna img{
  width: 250px;
  height: 250px;
}

.ReactModal__Content--after-open{
  width: 76%;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  height: 14.5%;
  transform: translate(-40%,-50%) !important;
}

 
 
.button-item button{
  position: relative;
}
.border1::before, .border2::before, .border3::before,.border4::before,.border5::before,.border6::before{
  content: "";
  display: inline-block;
  width: 26px;
  height: 26px;
  background-image: url("../../Assets/Img/true.png");
  background-repeat: no-repeat;
  background-size: 26px 26px;
  position: absolute;
  top: 30%;
  left: 10%;
  transform: translate(140%);
}


.img-blok{
  display: flex;
  flex-direction: column;
}
.special-find{
  position: relative;
}
.img-blok{
  position: absolute;
  top: 20%;
  left: 9%;
}

.special-biznes{
  position: relative;
  display: flex;
  flex-direction: column;
  background: hsla(0,0%,100%,.8);
  /* filter: blur(10px); */
}
.biznes-box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 81%;
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.biznes-mirror{
  position: absolute;
  top: 50%;
  left: 43%;
}
.biznes-img, .biznes-buttons, .custom-styles{
  z-index: -1;
}
.biznes-box p{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 18px;
  color: #7373aa;
  line-height: 140%;
}
.color-premiums p, .biznes-mirror p{
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 18px;
  color: #7373aa;
  line-height: 140%;
}
.special-color{
  position: relative;
}
.color-premiums{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
   z-index: 1000;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}