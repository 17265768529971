.forget-page .register, .code-forget-page .register{
    /* padding-top: 180px; */
    height: 89.5vh;
  
}
.forget-page .register-box{
    margin-top: 8px;
}

.code-forget-page  .register-input{
    padding-left: 10px;
}

.forget-page .register-btn{
    padding: 8px 125px;
    height: 40px;
    margin-bottom: -20px;
}

.register-page .register-btn{
    padding: 8px 105px;
    height: 40px;
 
    
}
.register-page .register-box:nth-child(3){
    margin-bottom: 38px;
}

