.container .resultpage-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  min-height: 100vh;
  justify-content: space-between;
  width: 600px;
}
.resultpage .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.resultpage-top {
  display: flex;
  align-items: center;
}
.resultpage-wrapper {
  width: 100%;
}
.resultpage {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.resultpage-name {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #01384d;
}
.resultpage-text {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #01384d;
  margin-left: 4px;
  width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* background-color: red; */
}
.resultpage-map {
  display: flex;
  align-items: center;
  border: 2px solid #512da8;
  border-radius: 30px;
  padding: 6px 9px;
  margin: 10px 0;
  padding-right: 5px;
}
.resultpage-subtext {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #01384d;
  width: 410px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* background-color: red; */
}

.resultpage-about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 30px;
}
.resultpage-avatar {
  height: 9.375rem;
  min-height: 9.375rem;
  min-width: 9.375rem;
  width: 9.375rem;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 1px 1px 11px -7px rgba(0, 0, 0, 0.6);
}
.resultpage-top .resultpage-map svg {
  margin-top: 0 !important;
}

.resultpage-item .resultpage-link {
  display: flex;
  align-items: center;
  background: #2253ef;
  width: 100%;
}
.resultpage-item {
  margin: 14px 0;
}

.resultpage-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
}
.resultpage-top {
  min-height: 20vh;
  width: 100%;
}

@media (max-width: 1450px) {
  .container {
    width: 1280px;
  }
}

@media (max-width: 1290px) {
  .container {
    width: 1280px;
    padding: 0 15px;
  }
}

@media (max-width: 1180px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 1020px) {
  .container {
    width: 100%;
  }
}
@media (max-width: 920px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 720px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 620px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
  .resultpage-footer,
  .container .resultpage-main,
  .resultpage-item .resultpage-link {
    width: 100%;
  }
  .resultpage .resultpage-about {
    margin-left: 5px;
  }
  .resultpage-avatar {
    height: 6.375rem;
    min-height: 6.375rem;
    min-width: 6.375rem;
    width: 6.375rem;
  }
}

@media (max-width: 520px) {
  .container {
    width: 400px;
  }
  .resultpage-footer,
  .container .resultpage-main,
  .resultpage-item .resultpage-link {
    width: 100%;
  }
  .resultpage .resultpage-top,
  .resultpage .resultpage-about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .resultpage-main .main-name,
  .resultpage-main .main-subname {
    text-align: center;
  }
}

@media (max-width: 420px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }

  .resultpage-footer,
  .container .resultpage-main,
  .resultpage-item .resultpage-link {
    width: 100%;
  }
  .resultpage .adminSocial-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .resultpage-main .main-subname {
    width: 100%;
  }
  .resultpage .container ul {
    width: 100%;
  }
}
