.footer .container,
.footer-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer {
  background-image: url("../../Assets/Img/f2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 25px;
  padding-bottom: 30px;
}

.footer-title {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.011em;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  /* width: 645px; */
  text-align: center;
  height: 49px;
  margin-top: 70px;
  margin-bottom: 15px;
}

.footer-text {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.011em;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 888px;
  height: 21px;
  color: #ffffff;
}

.footer-link {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  margin: 40px 0;
  background: #ffffff;
  border-radius: 8px;
  transition: 0.3s;
  color: #000000;
  border: 2px solid #673ab7;
}

.footer-link:hover {
  background-color: #673ab7;
  color: white;
  border: 2px solid white;
}

.footer-subtext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  letter-spacing: -0.011em;
}

.footer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.footer-links {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  transition: 0.3s;
  color: #ffffff;
  margin: 0px 20px;
}

.footer-info {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */

  text-align: center;
  letter-spacing: 0.005em;
  margin: 30px 0;
  color: #ffffff;
}
