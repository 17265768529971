.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.011em;
  color: #000000;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 440px;
  height: 146px;
}

.header-text {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 410px;
  height: 128px;
  letter-spacing: -0.011em;
  color: #000000;
  margin: 28px 0;
}

.header-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  text-decoration: none;
  color: #fff;
  border: 2px solid #673ab7;
  transition: 0.3s;
}
.header-link:hover {
  background: linear-gradient(180deg, white 0%, white 100%);
  color: #512da8;
  border: 2px solid #673ab7;
}
.header-subtext {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 260px;
  height: 22px;
  letter-spacing: -0.011em;
  color: #000000;
}

.header {
  /* padding: 110px 0; */
  position: relative;
  padding-bottom: 70px;
  background: rgba(87, 93, 251, 0.05);
}
.header-left {
  padding: 25px 0;
  padding-top: 85px;
}
.header-subtext {
  margin: 35px 0;
}
.header-middle img {
  width: 32px;
  height: 32px;
}

.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 51%;
}

.header-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

.telegram-icon {
  position: absolute;
  animation-name: telegramAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes telegramAn {
  from {
    transform: translate(-200%, -700%);
  }
  to {
    transform: translate(-200%, -820%);
  }
}
.shazam-icon {
  position: absolute;
  /* top: -50%;
        left:50%;
        transform: translate(180%,-410%); */
  animation-name: shazamAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes shazamAn {
  from {
    transform: translate(180%, -680%);
  }
  to {
    transform: translate(180%, -870%);
  }
}
.safari-icon {
  position: absolute;
  /* top: 0%;
        left:0%;
        transform: translate(-0%,-300%); */

  animation-name: safariAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes safariAn {
  from {
    transform: translate(0%, -700%);
  }
  to {
    transform: translate(0%, -900%);
  }
}

.amazon-icon {
  position: absolute;
  /* top: 50%;
    left:-40%;
    transform: translate(-120%,-190%); */

  animation-name: amazonAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes amazonAn {
  from {
    transform: translate(-120%, -540%);
  }
  to {
    transform: translate(-120%, -690%);
  }
}
.paypal-icon {
  position: absolute;
  /* top: 50%;
    left:40%;
    transform: translate(120%,-200%); */

  animation-name: paypalAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes paypalAn {
  from {
    transform: translate(90%, -540%);
  }
  to {
    transform: translate(120%, -690%);
  }
}
.snapchat-icon {
  position: absolute;
  /* top: 50%;
    left:0%;
    transform: translate(0%,-120%); */

  animation-name: snapchatAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes snapchatAn {
  from {
    transform: translate(-10%, -450%);
  }
  to {
    transform: translate(0%, -620%);
  }
}
.steam-icon {
  position: absolute;
  /* top: 90%;
    left:-90%;
    transform: translate(-200%,-30%); */
  animation-name: steamAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes steamAn {
  from {
    transform: translate(-240%, -430%);
  }
  to {
    transform: translate(-260%, -540%);
  }
}

.facebook-icon {
  position: absolute;
  /* top: 120%;
        left:150%;
        transform: translate(200%,50%); */
  animation-name: facebookAn;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes facebookAn {
  from {
    transform: translate(200%, -450%);
  }
  to {
    transform: translate(200%, -550%);
  }
}

.header-img {
  width: 470px;
  height: 500px;
  z-index: -1;
}


