.statistic {
  padding: 20px 0;
}

.statistic .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-title {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;

  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;

  width: 405px;
  height: 108px;
  padding: 0;
  color: #182f43;
}

.statistic-text {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 365px;
  height: 70.5px;
  color: #182f43;
}

.statistic-left {
  width: 45%;
}

.statistic-right {
  width: 55%;
}
.img-text {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;

  text-align: center;

  color: #000000;
}

.statistic-img {
  width: 716px;
}

.img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.statistic-right {
  display: flex;
  position: relative;
}
.img-box1 {
  position: absolute;
  top: 12%;
  left: 18%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
}
@keyframes img1BoxAn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.2);
  }
}

.img-box2 {
  position: absolute;
  top: 13%;
  left: 74%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.2s;
}
.img-box2 img {
  width: 140px;
  height: 140px;
}
.img-box3 {
  position: absolute;
  top: 37%;
  left: 56.5%;
  animation-name: img3BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 0.9s;
}
@keyframes img3BoxAn {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.3);
  }
}

.img-box3 img {
  width: 130px;
  height: 130px;
}
.img-box4 {
  position: absolute;
  top: 50%;
  left: 5%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.5s;
}

.img-box5 {
  position: absolute;
  top: 65%;
  left: 45%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.4s;
}

.img-box6 {
  position: absolute;
  top: 75%;
  left: 25%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 0.9s;
}

.img-box7 {
  position: absolute;
  top: 65%;
  left: 90%;
  animation-name: img1BoxAn;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1s;
}


@media (max-width:1220px) {
  .container{
    width: 1100px;
  }
  .img-box3{
    left: 47.5%;
    top: 35%;
  }
}

@media (max-width:520px) {
  
  .container{
    width: 400px;
  }
  .img-box3{
    top: 42%;
  }
}