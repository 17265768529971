.faq_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    list-style: none;
    padding: 0;
    margin: 0;

    background-color: #fff;
}

.faq_list__item {
    width: 100%;
    cursor: pointer;
    padding: 2px;
    padding-top: 00;
}

.faq_list__item .text_wrap {
    flex-grow: 2;
    background-color: #fff;
}

.faq_list__item__question_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;
    padding: 8px;

}




.faq_list__item .icon {
    transition: all 0.2s ease;
    padding: 10px;
    border-radius: 50%;
    color: #512DA8;
}


.faq_list__item .closer {
    transform: rotate(180deg);
    /* background: linear-gradient(180deg, #673AB7 0%, #512DA8 100%); */
    /* background-color: red; */
    padding: 10px;
    border-radius: 50%;
    color: white;
}

.faq_list__item__answer_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);

}

.faq_list__item__answer_wrapper .faq_text {
    margin: 0px 0px 0px 9px;



}

.faq_list__item__answer_wrapper.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}


.faq-page .nav {
    background-color: white;
}

.faq-details {
    /* margin: 80px 0; */
    padding: 30px 0;
}

.service-page  .details-title {
    font-family: 'Clarika Geometric';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    letter-spacing: -0.011em;
    text-align: center;
    color: #000000;
    margin-bottom: 25px;

}

.faq_list__item {
    background: #FFFFFF;
    padding: 25px 20px;
    background: #FFFFFF;
    transition: 0.3s;

    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    border: 2px solid white;
    border-radius: 14px;
    margin: 15px 0;

}

.faq_list__item:hover {
    border: 2px solid #4A3AFF;


    box-shadow: 0px 6px 16px rgba(74, 58, 255, 0.19);
    border-radius: 14px;
}

.service-page .faq_header {

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    width: 91%;
    height: 100%;
word-break: break-word;
    color: #170F49;
    /* background-color: red; */

}

 

@media(max-width:920px) {
    .container {
        width: 800px;
    }

 

}

@media(max-width:820px) {
    .container {
        width: 700px;
    }


}

@media(max-width:720px) {
    .container {
        width: 600px;
    }
    .service-page .faq_header {
        width: 91%;
    word-break: break-word;
    height: 57px;}
}

@media(max-width:620px) {
    .container {
        width: 500px;
    }
    .service-page .faq_text {
        max-height: 378.5px;
    }
}

@media(max-width:520px) {
    .container {
        width: 400px;
    }

 

}

@media(max-width:420px) {
    .container {
        width: 100%;
        padding: 0px 15px;
      }
    /* .service-page .faq_list__item .faq_header {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    
    } */
 
    /* .service-page .faq_text {
        max-height: 486.5px;
        margin-top: 0;
    } */

}