.content-title {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.011em;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 860px;
  height: 50.5px;
  color: #000000;
}

.content-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin: 25px 0;
  text-align: center;
  letter-spacing: -0.011em;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 660px;
  height: 63.5px;
  color: #000000;
}

.content-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  padding: 80px 0;
}
