.account-shartnoma {
    margin-left: 60px;
}

.account-shartnoma-box {
    width: 765px;
    height: 121px;
    margin: 25px 0;

    border: 1px solid rgba(0, 0, 0, 0.45);
    border-radius: 16px;
}

.account-shartnoma-subtitle {
    font-family: 'Clarika Geometric';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    padding: 18px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.45);

    color: #01384D;

}

.account-shartnoma-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Clarika Geometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    letter-spacing: -0.011em;
    color: #01384D;

    margin-top: 18px;
}

.account-shartnoma-blok {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 18px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.account-shartnoma-blok .account-shartnoma-subtitle {
    border-bottom: none;
}

.account-shartnoma-btn {
    font-family: 'Clarika Geometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 178px;
    height: 40px;
border: none;

    background: #E5ECFC;
    border-radius: 20px;

    color: #01384D;
}

.account-shartnoma-footer {
    margin: 15px 0;
}

.account-shartnoma-box2 {
    height: 150px;
}
 