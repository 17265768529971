.work-medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.work-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.work-title {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  margin-top: 20px;
  letter-spacing: -0.011em;
  color: #000000;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 345px;
  height: 51.5px;
}

.work-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin: 25px 0;
  text-align: center;
  letter-spacing: -0.011em;

  color: #000000;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 1060px;
  height: 62.5px;
}

.work-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}

.work-subtitle {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  text-align: center;

  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 245px;
  height: 58.5px;
  /* margin: 1px 0; */
  margin-top: 5px;
  color: #182f43;
}

.work-item:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  /* background-color: red; */
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  width: 370px;
  height: 41.5px;
  margin: 15px 0;
  color: #182f43;
}

.work-list {
  width: 32%;
}

.work-img {
  height: 100%;
  width: 245px;
  margin-left: 18px;
  object-fit: cover;
}

.work-list:nth-child(1) {
  animation-name: workAn1;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes workAn1 {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(20px);
  }
}

.work-list:nth-child(2) {
  animation-name: workAn2;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes workAn2 {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(20px);
  }
}
.work-list:nth-child(3) {
  animation-name: workAn3;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes workAn3 {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(20px);
  }
}
