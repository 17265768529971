 .modal-status-active{
   /* background: rgb(98, 234, 8); */
   color: white;
 }
 .premium-section-active{
   background: blueviolet;
   color: white !important;
 }
 .premium-info-active{
   color: white;
 }
 .premium-disabled {
  pointer-events: none;
  /* opacity: 0.; */
  display: none;
}
 .subscribe-box{
    font-size: 22px;
    text-align: center;
 }

 .subscribe-buttons{
    padding: 10px 15px;
    margin: 22px auto 0px auto;
    display: flex;
    justify-content: space-between;
 }
 .subscribe-btn{
    border: none;
    width: 125px;
    background-color: blueviolet;
    color: white;
    padding: 10px 5px;
    text-align: center;
 }
 .subscribe-choose{
   border: 2px solid #673ab7;
    font-family: "Clarika Geometric";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    width: 250px;
    height: 56px;
    margin-top: 30px;
    margin-left: 15px;
    border-radius: 12px;
    transition: 0.3s;
    cursor: pointer;
 }
 .subscribe-choose:hover{
   transition: 0.3s;
   background-color: #673ab7;
   color: white;
 }
 @media(max-width:920px){
   .ReactModal__Content{
      width: 35% !important;
   }
 }
 @media(max-width:420px){
   .ReactModal__Content{
      width: 82% !important;
   }
 }