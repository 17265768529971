.account-btn:nth-child(1) {
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 50px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  padding: 0px 20px;
  border: none;
}

.account-btn:nth-child(2) {
  border-radius: 50px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  background-color: white;
  color: black;
  padding: 5px 20px;
  border: none;
}
.account {
  width: 100%;
}
.account-top {
  background-color: white;
  width: 100%;
  margin-top: 5px;
  padding: 20px;
}

.account-first {
  width: 100%;
  height: 476px;
  border-radius: 10px;
}

.react-tel-input .flag-dropdown.open {
  border: none;
}

.account-top {
  display: flex;
  padding: 15px 30px;
  padding-top: 0;
  padding-left: 0;
}

.account-footer {
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 16px;
}

.account-headers {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
  padding: 10px 30px;
  color: #01384d;
}

.account-span {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}

.account-span label {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #01384d;
  margin-bottom: 5px;
}

.account-input {
  border: 1.5px solid #575dfb;
  border-radius: 10px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.011em;
  padding: 8px 25px;
  color: #01384d;
  width: 100%;
}

.account-span .form-control {
  border: 1.5px solid #575dfb;
  border-radius: 10px;
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.011em;
  width: 100%;
  padding-left: 45px !important;
  padding: 8px 25px;
  color: #01384d;
}

.account-button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 105px;
  height: 40px;
  margin: 15px 0 10px;
  margin-left: auto;
  border: none;
  color: #ffffff;
  background: linear-gradient(180deg, #673ab7 0%, #512da8 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px;
}
.account-first:nth-child(2) {
  height: 300px;
  margin-top: 25px;
}
