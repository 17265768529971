.useful{
    padding: 80px 0;
}
.useful .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.useful-title{
    font-style: normal;
font-weight: 800;
font-size: 40px;
line-height: 48px;
 text-align: center;
letter-spacing: -0.011em;
text-overflow: ellipsis;
white-space: wrap;
overflow: hidden;
 
width: 470px;
height: 53px;
color: #000000;
}
.useful-text{
    font-weight: 400;
font-size: 20px;
line-height: 32px;
display: flex;
align-items: center;
letter-spacing: -0.011em;
margin: 30px 0;
color: #000000;
text-overflow: ellipsis;
white-space: wrap;
overflow: hidden;
/* background-color: yellow; */
width: 635px;
height: 30px;


}

.useful-input{
    border: 1.5px solid #575DFB;
border-radius: 16px;
padding: 8px 40px;
padding-right: 10px;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
  outline: none;
letter-spacing: -0.011em;
width: 323px;
color: #000000;

}
.useful-form{
    display: flex;

    margin-top: 30px;
    position: relative;
}

.useful-btn{
    position: absolute;
    top: 55%;
    left: 7%;
    background-color: transparent;
    border: none;
   cursor: pointer;
    transform: translate(-50%,-50%);
}