.settings-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.settings-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.settings-title {
  font-family: "Clarika Geometric";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  padding: 10px 20px;
  color: #01384d;
}

.settings {
  min-height: 650px;
  background: #ffffff;
  padding: 0px;
  border: 0.5px solid #bfcdd3;
  border-radius: 8px;
}
.settings-list {
  border-top: 1px solid #01384d;
  padding: 20px 10px;
}

.settings-item {
  width: 24.3%;
  margin: 9px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.settings-pro{
  position: relative;
}
.pro-text{
  position: absolute;
   top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100px;
  height: 35px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9px);
  border-radius: 24px;
}